import React from 'react'
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AboutUs from './components/About US/AboutUs';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import OurClients from './components/Our Clients/OurClients';
import OurProjects from './components/Our Projects/OurProjects';
import Services from "./components/Services/Services";

const App = () => {

  return (
    <BrowserRouter>
      <div className="app">
        <Header />
        <OurProjects />
        <Services />
        <AboutUs />
        <OurClients />
        <Contact />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
