import React, {useEffect}  from 'react'
import './OurClients.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import client1 from '../../img/client1.png'
import client2 from '../../img/client2.png'
import client3 from '../../img/client3.png'
import client4 from '../../img/client4.png'
import client5 from '../../img/client5.png'
import client6 from '../../img/client6.png'
import client7 from '../../img/client7.png'
import client8 from '../../img/client8.png'
import  WOW from 'wowjs';
import 'animate.css';

const OurClients = () => {
    useEffect(() => {
        new WOW.WOW().init();
    }, [])
    SwiperCore.use([Autoplay]);
    return (
        <div className="ourClients" id="ourClients">
            <div className="container">
                <h3 className="ourClientsTitle">OUR CLIENTS</h3>
                <div className="ourClientsImgBlock">
                    <Swiper
                        slidesPerView = {2}
                        spaceBetween={10}
                        freeMode = {true}
                        loop = {true}
                        speed = {1500}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            500: {
                              slidesPerView: 3,
                              spaceBetween:0,
                            },
                            1000: {
                                slidesPerView: 4,
                                spaceBetween:0,
                            },
                            1278:{
                                slidesPerView:4,
                                spaceBetween:0,
                            }  
                          }}
                        >
                        <SwiperSlide className="client"><img src={client1} className="img1  wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                        <SwiperSlide className="client"><img src={client2} className="img2  wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                        <SwiperSlide className="client"><img src={client3} className="img3  wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                        <SwiperSlide className="client"><img src={client4} className="img4  wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                        <SwiperSlide className="client"><img src={client5} className="img5  wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                        <SwiperSlide className="client"><img src={client6} className="img6 wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                        <SwiperSlide className="client"><img src={client7} className="img7 wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                        <SwiperSlide className="client"><img src={client8} className="img8 wow animate__animated animate__zoomIn" alt=""/></SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default OurClients
