import React, {useEffect}  from 'react'
import axios from 'axios';
import { useForm } from "react-hook-form";
import s from './Contact.module.css'
import  WOW from 'wowjs';
import 'animate.css';

const Contact = () => {
    const { register, handleSubmit } = useForm();
    const onSubmit = (data, e) => {
        axios.post('http://truckstaff.tashin.uz/request/', data)
        e.target.reset();
    };
    useEffect(() => {
        new WOW.WOW().init();
    }, [])
    


    return (
        <div className="container wow animate__animated animate__fadeInUp" id="contact">
            <h3 className={s.contactTitle}>CONTACT US</h3>
            <div className={s.contact}> 
                <div className={s.contactInfos}>
                    <div className={s.contactInfo}>
                        <h3 className={s.contactInfoTitle}>01</h3>
                        <div className={s.contactInfoText}>
                            <h4>Inquiries</h4>
                            <p>For any inquiries, questions or  recommendations, please call:  +1 267-800-0304  ext 1105 or  fill out the following form</p>
                        </div>
                    </div>
                    <div className={s.contactInfo}>
                        <h3 className={s.contactInfoTitle}>02</h3>
                        <div className={s.contactInfoText}>
                            <h4>Head Office</h4>
                            <p>401 West Walnut Street, suite#21, Allentown PA 18012,</p>
                            <p>e-mail: info@truckstaff.us <br/>Phone: 888-598-4707<br/>Fax:   +1 888-580-8074</p>
                        </div>
                    </div>
                    <div className={s.contactInfo}>
                        <h3 className={s.contactInfoTitle}>03</h3>
                        <div className={s.contactInfoText}>
                            <h4>Employment</h4>
                            <p>Тo apply for a job, please send a cover letter together with your C.V. to info@truckstaff.us </p>
                        </div>
                    </div>   
                </div>
                <div className={s.contactForm}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="">Enter your name:</label>
                        <input {...register("full_name")}  placeholder="Lucas Black"/>
                        <label htmlFor="">Enter your email:</label>
                        <input {...register("email")} placeholder="lucasblack@gmail.com" />
                        <label htmlFor="">Enter your subject:</label>
                        <input {...register("subject")} placeholder="Job opportunity" />
                        <label htmlFor="">Enter your message:</label>
                        <textarea {...register("message")} placeholder="Message"></textarea>
                        <div className={s.btn}>
                            <button className={s.formBtn} type="submit">Submit</button>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Contact
