import React, {useEffect}  from 'react'
import './AboutUs.css'
import  WOW from 'wowjs';
import 'animate.css';
import aboutUsImg from '../../img/about.svg'
import aboutIpadImg from '../../img/aboutIpad.svg'



const AboutUs = () => {
    useEffect(() => {
        new WOW.WOW().init();
    }, [])
    return (
        <div className="aboutUs wow animate__animated animate__fadeInUp" id="aboutUs">
            <div className="container">
                <div className="aboutUsBlock">
                    <div className="aboutUsImage">
                        <img src={aboutUsImg} className="aboutUsImg" alt=""/>
                        <img src={aboutIpadImg} className="aboutIpadImg" alt=""/>
                    </div>
                    <div className="aboutUsDes wow animate__animated animate__fadeInRightBig">
                        <h3 className="aboutUsTitle">ABOUT US</h3>
                        <p className="AboutUsText">Our experienced consulting team provides high-level service and professional advice concerning all your trucking needs.</p>
                        <p className="AboutUsText">We can help you ranging from accounting, safety and after-hours services to establishing your own carrier company from the very beginning satisfying all legal requirements and making sure the process will be simple, quick and straightforward as possible for you.</p>
                        <p className="AboutUsText">We will make your life easier by preparing the required DOT Program, your safety documentation, and, additionally, you will not have to spend your time on getting the best deals and awesome discounts with the largest companies in trucking industry.</p>
                        <p className="AboutUsText">We can assist you with opening new MC and DOT numbers, acquiring already existing aged MC authority or even preparing a turnkey trucking company for you with drivers and trucks.</p>
                        <p className="AboutUsText">Choose what is most suitable for you and we will help you to make the first step of establishing your own prosperous business.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs
