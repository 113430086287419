import React, {useEffect}  from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay} from 'swiper';
import './Services.css'
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import  WOW from 'wowjs';
import 'animate.css';


SwiperCore.use([Pagination,Autoplay]);

const Services = () => {
    useEffect(() => {
        new WOW.WOW().init();
    }, [])
    return (
        <div className="servicesBlock wow animate__animated animate__fadeInUp" id="service">
            <div className="container">
                <h2 className="servicesBlockTitle">SERVICES</h2>
                <div className="serviceSlider">
                    <Swiper
                        spaceBetween={70}
                        slidesPerView={1}
                        slidesPerGroup={1}
                        loop={true}
                        pagination={{ clickable: true }}
                        loopFillGroupWithBlank={true}
                        speed = {2000}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            550: {
                              slidesPerView: 2,
                              spaceBetween:30,
                              slidesPerGroup:2,
                            },
                            770: {
                                slidesPerView: 3,
                                slidesPerGroup:3,
                                spaceBetween:60,
                              },
                          }}
                    >
                        
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">01</h3>
                                    <div className="serviceInfoText">
                                        <h4>Turnkey trucking business</h4>
                                        <p>Vetted and verified MC/DOT entity with clean and active 3-8 year authority with 3 team drivers and trucks. </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">02</h3>
                                    <div className="serviceInfoText">
                                        <h4>Aged MC authority</h4>
                                        <p>Active aged trucking business with clean record and no violations or claims </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">03</h3>
                                    <div className="serviceInfoText">
                                        <h4>Accounting services</h4>
                                        <p>Account Payable, account receivable, paperwork collection, fuel cards monitoring. </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">04</h3>
                                    <div className="serviceInfoText">
                                        <h4>After-hours update</h4>
                                        <p>Constant update with brokers and drivers, replying to their e-mails and phone calls.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">05</h3>
                                    <div className="serviceInfoText">
                                        <h4>Set-up with factoring company</h4>
                                        <p>24% from the average market rate, 1.9% initial factoring rate with the possibility of reduction.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">06</h3>
                                    <div className="serviceInfoText">
                                        <h4>Handling safety documentation</h4>
                                        <p>Our safety documentation meet all required FMCSA and legal standards and protect you from any legal action.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">07</h3>
                                    <div className="serviceInfoText">
                                        <h4>Insurance quote support </h4>
                                        <p>4 different insurance agents who can get you the best quote with unlimited growth option  </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="serviceSlide">
                                    <h3 className="serviceInfoTitle">08</h3>
                                    <div className="serviceInfoText">
                                        <h4>Set-Up with Truck Rental companies</h4>
                                        <p>Penske\Ryder accounts, 2018-2022 Freightliner, Volvo at $950, 0.13 cents\mile rate.</p>
                                    </div>
                                </div>
                            </SwiperSlide>  
                    </Swiper>
                    
                </div>
            </div>
        </div>
    )
}

export default Services
