import React, {Component} from 'react'
import { HashLink } from 'react-router-hash-link';
import logo from '../../img/logo.svg'
import btnIcon from '../../img/btnicon.svg'
import './Header.css'
import Menu from '../../img/menuIcon.svg'
import Close from '../../img/closeIcon.svg'
 
export default class Header extends Component {
    state = {
        toggle: false
    }

    menuToggle = () =>{
        this.setState({toggle: !this.state.toggle})
    }
    render() {
        const {toggle} = this.state;
        return (
            <div className="header" id="header">
                <div className="container">
                    <div className="topnav">
                        <div className="logo">
                            <HashLink smooth to="#header">
                                <img src={logo} alt=""/>
                            </HashLink>
                        </div>
                        <div className="topnavMenu">
                            <ul>
                                <li><HashLink smooth to="#ourProjects">Projects</HashLink></li>
                                <li><HashLink smooth to="#service">Services</HashLink></li>
                                <li><HashLink smooth to="#aboutUs">About</HashLink></li>
                                <li> <HashLink smooth to="#contact">Contacts</HashLink></li>
                            </ul>
                        </div>
                        <div className="BurgerMenu">
                            <div className="menu" onClick={this.menuToggle}>
                                <img src={Menu} alt="" width="20"/>
                            </div>
                            <nav className={toggle ? "toggle" : ""}>
                                <ul>
                                    <li><HashLink smooth to="#ourProjects">Projects</HashLink></li>
                                    <li><HashLink smooth to="#service">Services</HashLink></li>
                                    <li><HashLink smooth to="#aboutUs">About</HashLink></li>
                                    <li><HashLink smooth to="#contact">Contacts</HashLink></li>
                                    <li className="close" onClick={this.menuToggle}>
                                        <img src={Close} alt="" width="20"/>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="headerContent">
                        <h1 className="headerContentTitle">TRUCKSTAFF <br/> SOLUTIONS</h1>
                        <h3 className="headerContentText">Supportive services for all your logistics needs</h3>
                        <div className="btnBlock">
                           <HashLink smooth to="#aboutUs" className="headerContentBtn">
                                Know more
                            <img src={btnIcon} alt=""/>
                            </HashLink>
                            <HashLink smooth to="#contact" className="headerContentBtn2">
                                Contact Us
                            </HashLink> 
                        </div>
                    </div>
                </div>
            </div>
                
        )
    }
}













// <div className="BurgerMenu">
            //     <div className="menu" onClick={this.menuToggle}>
            //         <img src={Menu} alt="" width="20"/>
            //     </div>
            //     <nav>
            //         <ul className={toggle ? "toggle" : ""}>
            //             <li><Link to="/">Home</Link></li>
            //             <li><Link to="/product">Product</Link></li>
            //             <li><Link to="/contact">Contact</Link></li>
            //             <li><Link to="/about">About</Link></li>
            //             <li><Link to="/login">Login / Register</Link></li>
            //             <li className="close" onClick={this.menuToggle}>
            //                 <img src={Close} alt="" width="20"/>
            //             </li>
            //         </ul>
            //     </nav>
            // </div>