import React, {useEffect}  from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
import './OurProjects.css'
import  WOW from 'wowjs';
import 'animate.css';
import car1 from '../../img/car1.png'
import car2 from '../../img/car2.png'
import car3 from '../../img/car3.png'
import car4 from '../../img/car4.png'
import car5 from '../../img/car5.png'
import car6 from '../../img/car6.png'
import car7 from '../../img/car7.png'
import car8 from '../../img/car8.png'
import car9 from '../../img/car9.png'
import car10 from '../../img/car10.png'
import car11 from '../../img/car11.png'
import car12 from '../../img/car12.png'
import car13 from '../../img/car13.png'
import dot from '../../img/dot.svg'
import 'swiper/swiper.scss';


const OurProjects = () => {
    useEffect(() => {
        new WOW.WOW().init();
    }, [])
    SwiperCore.use([Autoplay]);
    return (
        <div className="ourProjects wow animate__animated animate__fadeInUp" id="ourProjects">
            <div className="container">
                <h2 className="ourProjectsTitle">OUR PROJECTS</h2>
                <div className="slider">
                    <Swiper
                        slidesPerView = {1}
                        spaceBetween={10}
                        freeMode = {true}
                        loop = {true}
                        speed = {1500}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            400: {
                              slidesPerView: 2,
                              spaceBetween:0,
                            },
                            1000: {
                                slidesPerView: 3,
                                spaceBetween:0,
                            },
                            1281:{
                                slidesPerView:4,
                                spaceBetween:90,
                            }  
                          }}
                        >
                        <SwiperSlide><img src={car1} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car2} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car3} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car4} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car5} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car6} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car7} className="carimg" alt=""/></SwiperSlide>
                    </Swiper>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        freeMode = {true}
                        loop = {true}
                        speed = {1500}
                        autoplay={{
                            delay: 965,
                            disableOnInteraction: false,
                            reverseDirection:true
                        }}
                        breakpoints={{
                            400: {
                              slidesPerView: 2,
                              spaceBetween:0,
                            },
                            1000: {
                                slidesPerView: 3,
                                spaceBetween:0,
                            },
                            1281:{
                                slidesPerView:4,
                                spaceBetween:90,
                            }  
                          }}
                        >
                        <SwiperSlide><img src={car8} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car9} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car10} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car11} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car12} className="carimg" alt=""/></SwiperSlide>
                        <SwiperSlide><img src={car13} className="carimg" alt=""/></SwiperSlide>
                    </Swiper>
                </div>
                <div className="ourProjectsInfo">
                    <h3 className="ourProjectsInfoNumber">206</h3>
                    <h3 className="ourProjectsInfoText">PROJECTS</h3>
                    <img className="ourProjectsDot" src={dot} alt=""/>
                    <h3  className="ourProjectsInfoText">SINCE</h3>
                    <h3 className="ourProjectsInfoNumber">2017</h3>
                </div>
            </div>
        </div>
    )
}

export default OurProjects
