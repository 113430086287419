import React from 'react'
import s from './Footer.module.css'
import linkedIn from '../../img/linkedIn.svg'
import facebook from '../../img/facebook.svg'
import instagram from '../../img/instagram.svg'
import youtube from '../../img/youtube.svg'

const Footer = () => {
    return (
        <div className={s.footer}>
            <div className="container">
                <div className={s.footerIcons}>
                    <a href="https://www.youtube.com/channel/UCI_eKL5Q21Tzq_PTbgvSIpw">
                        <img src={youtube} alt=""/>
                    </a>
                    <a href="">
                        <img src={linkedIn} alt=""/>
                    </a>
                    <a href="https://www.facebook.com/Truckstaff-Solutions-%D0%92%D1%81%D0%B5-%D0%BE-%D1%82%D1%80%D0%B0%D0%BA%D0%B8%D0%BD%D0%B3%D0%BE%D0%B2%D0%BE%D0%BC-%D0%B1%D0%B8%D0%B7%D0%BD%D0%B5%D1%81%D0%B5-%D0%B2-%D0%A1%D0%A8%D0%90-106004461619755">
                        <img src={facebook} alt=""/>
                    </a>
                    <a href="https://www.instagram.com/truckstaff/">
                        <img src={instagram} alt=""/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Footer
